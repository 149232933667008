import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Container } from 'src/components/container'
import { WafFriendlyPass } from 'src/types/waf-friendly-pass'
import { fetchWithCache } from 'src/utils/fetchWithCache'
import { wpApiUrl } from 'src/utils/wpApiUrl'
import styles from './index.module.scss'
import LinkButton from 'src/components/button/LinkButton'
import { AboutWahtIsPass } from 'src/components/about/whatIsPass'
import { AboutHowToUse } from 'src/components/about/howToUse'
import { TicketCard } from 'src/components/ticketCard'
import { FaQList } from 'src/components/faq/list'
import { CarouselRecommendWaf } from 'src/components/caroucel/recommendWaf'
import { isStaging } from 'src/utils/isStaging'
import Image from 'next/image'
import { Routes } from 'src/utils/routes'
import Link from 'next/link'
import { useIsAuthenticated } from 'src/store/userUid'
import { AboutServiceCarousel } from 'src/components/about/serviceCarousel'
import { AboutHotelCarousel } from 'src/components/about/hotelCarousel'
import { PassInfomation } from 'src/components/passInfomation'

type Props = {
  serviceList: WafFriendlyPass.service[]
  ticketList: WafFriendlyPass.ticket[]
  spotTypeList: WafFriendlyPass.taxonomy[]
  recommendList: WafFriendlyPass.recommend[]
  hotelList: WafFriendlyPass.hotel[]
}

const Home = () => {
  const [loadOnce, setLoadOnce] = useState(false)
  const [services, setServices] = useState<WafFriendlyPass.service[]>([])
  const [tickets, setTickets] = useState<WafFriendlyPass.ticket[]>([])
  const [hotels, setHotels] = useState<WafFriendlyPass.hotel[]>([])
  const [recommends, setRecommends] = useState<WafFriendlyPass.recommend[]>([])

  const firstLoad = async () => {
    const services = (await fetchWithCache(
      `${wpApiUrl.service}?per_page=8&_fields=id,acf`
    )) as Props['serviceList']
    const hotels = (await fetchWithCache(
      `${wpApiUrl.hotel}?per_page=6&_fields=id,acf`
    )) as Props['hotelList']

    setServices(
      isStaging ? services : services.filter((item) => item.acf.status)
    )
    setHotels(isStaging ? hotels : hotels.filter((item) => item.acf.status))
    setTickets(
      (await fetchWithCache(
        `${wpApiUrl.campaign}?per_page=4&_fields=id,acf`
      )) as Props['ticketList']
    )
    setRecommends(
      (await fetchWithCache(
        `${wpApiUrl.officialRecommend}`
      )) as Props['hotelList']
    )
  }

  useEffect(() => {
    if (loadOnce) {
      return
    }
    firstLoad()
    setLoadOnce(true)
  }, [])

  const { isAuthenticated } = useIsAuthenticated()
  useEffect(() => {
    isAuthenticated
  }, [isAuthenticated])

  return (
    <div className={styles.top}>
      <section className={styles.topKv}>
        <span className={styles.topKvAssets}>
          <svg width="240" height="202" viewBox="0 0 240 202" fill="none">
            <path
              className={styles.miterlimit}
              d="M125.604 106C119.177 110.669 115.42 118.047 111.087 124.692C105.3 133.568 98.1057 141.536 89.8564 148.197C84.2289 152.739 76.1234 156.739 70.1442 152.659C64.3249 148.675 65.6679 138.461 71.9028 135.146C78.1378 131.847 86.563 135.242 89.8883 141.441C93.2137 147.64 91.9347 155.623 87.9539 161.424C83.9731 167.224 77.6742 171.113 71.0715 173.614C58.9373 178.204 45.652 178.586 32.9422 181.232C20.2325 183.877 7.02714 189.677 1 201.135"
              stroke="#F4F6FF"
            />
            <path
              d="M193.501 110.172C195.204 109.032 196.073 106.933 195.761 104.727L188.388 66.2266C205.811 55.3943 216.52 45.8168 213.602 41.1018C210.684 36.387 197.335 41.6969 179.867 52.4559L148.701 28.6779C146.865 27.4117 144.6 27.2554 142.82 28.2707L138.599 30.6752C137.961 31.0388 137.54 31.6986 137.452 32.4769C137.364 33.2552 137.615 34.0626 138.139 34.6815C138.139 34.6815 156.799 56.8126 162.56 63.5405C149.576 71.9858 142.469 76.8657 142.469 76.8657L133.491 71.0282C132.508 70.2938 131.179 70.2359 130.136 70.881L128.616 71.8215C127.919 72.2532 127.428 72.9526 127.263 73.7586C127.097 74.562 127.27 75.3982 127.741 76.07L134.77 86.1143C132.659 87.8825 131.631 90.0296 132.435 91.3285C133.239 92.6275 135.617 92.6625 138.143 91.5651L143.996 102.338C144.387 103.059 145.058 103.587 145.851 103.797C146.646 104.009 147.491 103.882 148.188 103.45L149.708 102.51C150.751 101.865 151.292 100.649 151.074 99.442L149.854 88.8001C149.854 88.8001 157.394 84.6193 170.746 76.7683C174.196 84.9264 185.675 111.5 185.675 111.5C185.995 112.246 186.608 112.833 187.342 113.099C188.077 113.368 188.854 113.283 189.466 112.877L193.501 110.172Z"
              fill="white"
            />
            <path
              d="M168 60L162.5 63.5L142.5 40L146 39L168 60Z"
              fill="#FFC85F"
            />
            <path
              d="M176.105 73.3555L170.509 77.0009L183.004 105.501L187 102L176.105 73.3555Z"
              fill="#FFC85F"
            />
          </svg>
        </span>
        <span className={styles.topKvAssets}>
          <svg width="183" height="155" viewBox="0 0 183 155" fill="none">
            <path
              className={styles.miterlimit}
              d="M86.8526 81.1594C91.7733 84.7343 94.6498 90.3835 97.967 95.4713C102.398 102.267 107.906 108.368 114.223 113.468C118.531 116.945 124.737 120.008 129.315 116.884C133.771 113.834 132.743 106.013 127.969 103.475C123.195 100.95 116.744 103.548 114.198 108.295C111.652 113.041 112.631 119.154 115.679 123.595C118.727 128.036 123.55 131.013 128.605 132.929C137.896 136.443 148.068 136.735 157.799 138.761C167.53 140.786 177.641 145.227 182.256 154"
              stroke="#F4F6FF"
            />
            <path
              d="M34.8642 84.3538C33.56 83.4809 32.8947 81.8736 33.1339 80.1846L38.7789 50.7067C25.4387 42.4129 17.2396 35.0798 19.4736 31.4698C21.7074 27.8599 31.9284 31.9254 45.3033 40.1631L69.1651 21.9574C70.5712 20.9879 72.3051 20.8683 73.6684 21.6456L76.8999 23.4866C77.3884 23.765 77.7109 24.2702 77.7782 24.8661C77.8454 25.462 77.6532 26.0802 77.252 26.5541C77.252 26.5541 62.9653 43.4989 58.5538 48.6501C68.4951 55.1163 73.9371 58.8526 73.9371 58.8526L80.8109 54.3831C81.5635 53.8208 82.5814 53.7764 83.3797 54.2704L84.5433 54.9905C85.0774 55.321 85.4532 55.8565 85.5791 56.4737C85.7063 57.0887 85.574 57.729 85.2137 58.2433L79.8314 65.9338C81.4477 67.2877 82.2349 68.9316 81.6195 69.9261C81.004 70.9207 79.183 70.9475 77.2488 70.1073L72.7679 78.3559C72.4683 78.9076 71.9545 79.3119 71.3474 79.4724C70.7388 79.6353 70.0919 79.5376 69.5579 79.2072L68.3942 78.4871C67.5959 77.9931 67.1814 77.0625 67.3487 76.1382L68.2827 67.9902C68.2827 67.9902 62.5094 64.7892 52.2866 58.778C49.6452 65.0243 40.8558 85.3708 40.8558 85.3708C40.6108 85.9414 40.1419 86.3913 39.58 86.595C39.0167 86.8006 38.4218 86.7353 37.9535 86.4246L34.8642 84.3538Z"
              fill="white"
            />
            <path
              d="M54.3918 45.9393L58.6029 48.6191L73.916 30.6263L71.2362 29.8606L54.3918 45.9393Z"
              fill="#FFC85F"
            />
            <path
              d="M48.185 56.1651L52.4698 58.9562L42.9024 80.7772L39.843 78.0969L48.185 56.1651Z"
              fill="#FFC85F"
            />
          </svg>
        </span>
        <div className={`${styles.topKvBg} utilsPc`}>
          <Image
            src="/images/kv_pc.svg"
            width={1440}
            height={500}
            loading={'lazy'}
            priority={false}
            alt=""
          />
        </div>
        <div className={`${styles.topKvBg} utilsSp`}>
          <Image
            src="/images/kv_sp.svg"
            width={414}
            height={500}
            loading={'lazy'}
            priority={false}
            alt=""
          />
        </div>
        <div className={styles.topKvInner}>
          <p className={styles.topKvLogo}>
            <Image
              src="/images/logo_white.png"
              width={310.5}
              height={166.5}
              alt="ワーケーションフレンドリーパス produced by W@F"
            />
          </p>
          <h1 className={styles.topKvText}>
            福岡でのビジネス・観光を
            <br />
            もっと楽しく、もっと便利に
          </h1>
          <p className={styles.topKvSubText}>
            ワフパスは、福岡市での滞在中にパス画面を提示して使えるお得な特典や会員限定の宿泊プランを紹介する
            <br />
            ウェブサービスです。会員登録して、福岡来訪時に是非ご活用ください。{' '}
          </p>
          <div className={styles.topKvLinkWrap}>
            <div className={styles.topKvLink}>
              <span>ワフパス会員の方</span>
              <Link href={Routes.MY_PAGE} passHref prefetch={false}>
                <a>パスを発行する</a>
              </Link>
            </div>
            <div className={styles.topKvLink}>
              <span>まだ会員ではない方</span>
              <Link href={Routes.SIGN_IN} passHref prefetch={false}>
                <a>無料会員登録はこちら</a>
              </Link>
            </div>
          </div>
          <PassInfomation />
          <Link href={Routes.ABOUT} passHref prefetch={false}>
            <a className={styles.topKvAboutLink}>ワフパスについてはこちら</a>
          </Link>
        </div>
      </section>

      <section className={styles.topSection}>
        <div className={styles.topContainer}>
          <p className={styles.topSectionLabel}>
            ＼福岡滞在中の方向けの<span>旅ナカ</span>
            サービス／
          </p>
          <h2 className={styles.topSectionHeading}>ワフパス特典</h2>
          <p className={styles.topSectionText}>
            パスをお持ちの方が福岡市内の施設で利用できる会員限定の特典サービスです。
            <br className="utilsPc" />
            観光施設や市内交通などの観光で使える特典はもちろんのこと、
            <br className="utilsPc" />
            コワーキングスペースや会員限定の研修プログラムなど、ビジネスで使える特典も数多くご用意しています。
          </p>
          <p className={styles.topSectionInfo}>
            ※特典によっては事前の予約が必要な場合がありますので、特典紹介ページをよくご確認ください。
          </p>
        </div>
        {services && (
          <div className={styles.topService}>
            <AboutServiceCarousel services={services} />
            <div className={styles.topServiceButton}>
              <LinkButton
                href="/spot/page/1"
                size="lg:Small_mq:Large"
                color="blue"
              >
                特典一覧へ
              </LinkButton>
            </div>
          </div>
        )}
      </section>

      <section className={styles.topSection}>
        <div className={styles.topContainer}>
          <p className={styles.topSectionLabel}>
            ＼福岡への滞在を検討中方向けの<span>旅マエ</span>サービス／
          </p>
          <h2 className={styles.topSectionHeading}>
            <span className={styles.topSectionHeadingAppeal}>会員限定</span>
            宿泊プラン
          </h2>
          <p className={styles.topSectionText}>
            ワフパスには、長く滞在する方やリピーターに最適な会員限定の宿泊プランが満載。{' '}
            <br className="utilsPc" />
            １泊から利用できるお得なプランも多数掲載していますので、
            <br className="utilsPc" />
            福岡での滞在先(宿泊先)が決まっていない方はぜひチェックしてください。
          </p>
        </div>
        {hotels && (
          <div className={styles.topHotels}>
            <AboutHotelCarousel hotels={hotels} />
            <div className={styles.topHotelsButton}>
              <LinkButton
                href="/hotel/page/1"
                size="lg:Small_mq:Large"
                color="blue"
              >
                宿泊プラン一覧へ
              </LinkButton>
            </div>
          </div>
        )}
      </section>

      <section className={styles.topSection}>
        <div className={styles.topContainer}>
          <h2 className={styles.topSectionHeading}>
            <span className={styles.topSectionHeadingAppeal}>要チェック</span>
            交通キャンペーン
          </h2>
          <p className={styles.topSectionText}>
            交通・移動に関わるお得なキャンペーンを実施中!
            <br className="utilsPc" />
            現地で使えるワフパス特典とあわせて利用し、よりお得で便利に福岡滞在をお楽しみいただけます。
          </p>
        </div>
        {tickets && (
          <div className={styles.topTickets}>
            {tickets.map((ticket, idx) => (
              <TicketCard key={idx} {...ticket} login={isAuthenticated} />
            ))}
          </div>
        )}
      </section>

      <div className={styles.topAbout}>
        <div className={styles.topAboutContainer}>
          <h2 className={styles.topAboutHeading}>ワフパスとは？</h2>
          <div className={styles.topAboutHead}>
            <AboutWahtIsPass />
          </div>
          <div className={styles.topAboutHowToUse}>
            <AboutHowToUse />
          </div>
        </div>
      </div>

      <section className={styles.topFaQ}>
        <Container mtNone>
          <h2 className={styles.topFaQHeading}>よくある質問</h2>
          <FaQList />
        </Container>
      </section>

      {recommends && (
        <section className={styles.topRecommend}>
          <Container mtNone>
            <h2 className={styles.topRecommendHeading}>
              運営からのおすすめコンテンツ
            </h2>
            <div className={styles.topRecommendCarousel}>
              <CarouselRecommendWaf items={recommends} />
            </div>
          </Container>
        </section>
      )}
    </div>
  )
}
export default Home
